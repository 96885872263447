import React, { useState, useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { SnackbarContext } from "../context/SnackbarContext";

const useStyles = makeStyles(theme => ({
    fileInputButton: {
        backgroundColor: green[400],
        color: 'white',
        '&:hover': {
            backgroundColor: green[200],
        },
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '500'
    },
    spinner: {
        display: "inline-block",
        verticalAlign: "middle",
        color: "#666",
        lineHeight: 1,
        width: "6em",  // 4倍にサイズ変更
        height: "6em",
        border: "0.12em solid rgba(102, 102, 102, 0.3)",
        borderTopColor: "currentColor",
        borderRadius: "50%",
        boxSizing: "border-box",
        animation: "$rotate 1s linear infinite",
        position: 'absolute',  // 中央に配置するための設定
        top: '30%',
        left: '40%',
        transform: 'translate(-50%, -50%)',  // 完全に中央に位置させる
        zIndex: 10,  // 最前面に表示
    },
    "@keyframes rotate": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(360deg)",
        },
    },
    blurredContent: {
        filter: "blur(2px)",  // ブラー効果を追加
        position: 'relative',
        zIndex: 1,  // 背面に表示
    },
}));

const DataModal = (props) => {
    const { profile } = useContext(ApiContext);
    const { newSnack } = useContext(SnackbarContext);
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [isLoading, setIsLoading] = useState(false);  // ローディング状態管理
    const { t } = useTranslation();
    const classes = useStyles();

    const [token] = useState(localStorage.getItem('current-token'));

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);  // アップロード開始
        const formData = new FormData();

        const fileName = file.name.replace(/\s/g, "_");  // スペースをアンダースコアに変換
        formData.append('file', new File([file], fileName));
        formData.append('description', description);
        formData.append('title', title);
        formData.append('user_id', profile.id);
        formData.append('price', priceLevel); // 価格情報を追加

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/user/upload/`, formData, {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log("File uploaded successfully", response.data);
            newSnack("success",  t('DataModal16'));
            props.handleClose();  // モーダルを閉じる
            setFile("");
            setDescription("");
            setTitle("");
        } catch (error) {
            newSnack("error", t('DataModal15'));
        } finally {
            setIsLoading(false);  // アップロード完了後、ローディング停止
        }
    };

    const [priceLevel, setPriceLevel] = useState("free"); // 初期値を無料に設定
    const priceLevels = [
        { key: "free", label: t('DataModal17'), label2:t('DataModal24') },
        { key: "low", label: t('DataModal18'), label2:t('DataModal25')  },
        { key: "medium", label: t('DataModal19'), label2:t('DataModal26')  },
        { key: "high", label: t('DataModal20'), label2:t('DataModal27')  },
        { key: "great", label: t('DataModal21'), label2:t('DataModal28')  },
        { key: "premium", label: t('DataModal22'), label2:t('DataModal29')  },
    ];

    const handlePriceChange = (level) => {
        setPriceLevel(level); // 選択された価格レベルを設定
    };

    return (
        <Dialog
            className="animated"
            open={props.isVisible}
            onClose={props.handleClose}
            aria-labelledby="max-width-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogContent
                className={`card w-000 shadow-xss rounded-xxl border-0 ps-0 pt-4 pe-0 pb-3 mt-1 mb-1 ${isLoading ? classes.blurredContent : ''}`}
            >
                <div
                    className="position-absolute right-0 top-5 pointer me-3"
                    onClick={props.handleClose}
                >
                    <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
                </div>

                <div className="card-body ps-2 pe-2 mt-1 pt-0 pb-1 position-relative">
                    {
                        profile.img ?
                        <figure className="avatar position-absolute ms-2 mt-2 top---5">
                            <img
                                src={profile.img.startsWith("http") ? profile.img : process.env.REACT_APP_API_URL + profile.img}
                                alt="icon"
                                className="shadow-sm rounded-circle w30"
                            />
                        </figure> :
                        <figure className="avatar position-absolute ms-2 mt-2 top-5">
                            <img
                                src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                                alt="icon"
                                className="shadow-sm rounded-circle w30"
                            />
                        </figure>
                    }

                    <textarea
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        name="text"
                        className="h50 bor-0 w-100 rounded-xxl p-2 ps-5 font-xss fw-500 theme-dark-text border-light-md theme-dark-bg mb-2"
                        cols="30"
                        rows="10"
                        placeholder={t('DataModal3')}
                    ></textarea>
                </div>

                <input 
                    type="file" 
                    className={!file ? "p-2 me-2 ms-2 bg-current text-white rounded-xxl font-xssss fw-500 border-light-md theme-dark-bg" : "p-2 bg-current text-white rounded-xxl font-xssss fw-500 border-light-md theme-dark-bg"}
                    onChange={handleFileChange}
                    style={{marginTop: "0px"}}
                />

                <h2 className="ms-2 me-2  font-xssss text-center mt-3 lh-1 text-grey-500 fw-600">
                    {t('DataModal13')}
                </h2>



                <div className="price-selection row justify-content-center ms-1 me-1 mb-5">
                    <h2 className=" font-xssss text-center mt-3 lh-1 text-grey-500 fw-600">
                        {t('DataModal23')}
                    </h2>
                    <div className="price-buttons">
                    {priceLevels.map((level) => (
                    <Button
                        key={level.key}
                        onClick={() => handlePriceChange(level.key)}
                        className={
                            priceLevel === level.key 
                                ? "shadow-xss card-body ps-1 pe-1 pt-0 pb-0 pointer text-center ms-1 mb-1 me-1 bg-current text-grey-700" 
                                : "border shadow-xss card-body ps-1 pe-1 pt-0 pb-0 text-center ms-1 mb-1 me-1 bg-white"
                        }
                    >
                        <h4 className={
                            priceLevel === level.key 
                                ? "fw-400 font-xssss text-white mt-1 mb-0" 
                                : "fw-400 font-xssss text-grey-700 mt-1 mb-0"
                        }>
                            {level.label} {/* 表示名 */}
                            <br></br>
                            <h4 className={
                            priceLevel === level.key 
                                ? "fw-400 font-xssssss text-white mt-0 mb-1" 
                                : "fw-400 font-xssssss text-grey-700 mt-0 mb-1"
                        }>
                            {level.label2} {/* 表示名 */}
                        </h4>
                        </h4>
                        <br></br>
                        {/* <h4 className={
                            priceLevel === level.key 
                                ? "fw-400 font-xssss text-white mt-1 mb-1" 
                                : "fw-400 font-xssss text-grey-700 mt-1 mb-1"
                        }>
                            
                        </h4> */}
                    </Button>
                        ))}
                    </div>
                </div>


 

                <div className="card-body p-0 d-flex">
                    <div className="file-upload-container position-absolute right-15 bottom-5">
                        {title && file ? (
                            <Button onClick={handleSubmit}
                                className="bg-current text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                                variant="contained"
                            >
                                {t('DataModal11')}
                            </Button>
                        ) : (
                            <Button
                                className="text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                                variant="contained"
                                disabled
                            >
                                {t('DataModal12')}
                            </Button>
                        )}
                    </div>
                </div>

                {/* ローディングアイコン */}
                {isLoading && (
                    <span className={classes.spinner}></span>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default DataModal;
