import React, {
  useContext,
  //  useState

} from "react";

import { ApiContext } from "../context/ApiContext";
import { useHistory, Link } from "react-router-dom";
import LinearProgress from "@mui/joy/LinearProgress";
import { useCookies } from "react-cookie";
import clsx from 'clsx';
import copy from '../context/clipboard';

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from "../context/SnackbarContext";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    marginBottom: "1rem",
    position: "relative",
  },
  cardContent: {
    minHeight: "130px",
  },
  media: {
    height: 140,
  },
  moveToBtn: {
    position: "absolute",
    right: "10px",
    bottom: "80px",
    zIndex: "2"
  }
});



const RecommendEach = (pageData, isfollow) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const { profile, newUserIntPage,  monoposts } = useContext(ApiContext);


  const toMonopage = () => {
    // if (token !== undefined) {
    // const createdIntData = new FormData();
    // createdIntData.append("intPageId", pageData.pageData.id);
    // newUserIntPage(pageData.pageData.id);
    // }
    history.push("/mono/" + pageData.pageData.id);
  };


  const toSpecificUserPage = (id) => {
    if (profile.userProfile === id) {
      history.push("/userpage");
    } else {
      history.push(`/user/${id}`);
    }
  };

  const sharePage = () => {
    copy(window.location.href);
    newSnack("info", t('Mono2'));
  }

  // console.log(pageData.pageData)
  // const [rate, setRate] = useState([]);
  // const [rates, setRates] = useState([]);

  const postsforpage = monoposts.filter((each) => {
    return each.reviewTo === Number(pageData.pageData.id);
  });

  // const rates = postsforpage.map((post) => {
  //   return post.rating;
  // });

  // let sum = 0;
  // for (let i = 0; i < rates.length; i++) {
  //   sum += rates[i];
  // }

  // rates&& setRate(Math.round(sum/rates.length))
  // Math.round(sum / rates.length);

  // console.log("pageData/rating ", pageData.pageData.rating);
  const randnum = Math.floor(Math.random() * 29);
  const imageUrl = `https://ow6r85j5w096clt.site/media/image/background_${1 + randnum}.jpg`;
  // console.log(pageData.pageData.img)
  const createdDate = new Date(pageData.pageData.created_on);
  const today = new Date();
  const threeDaysAgo = new Date(today);
      threeDaysAgo.setDate(today.getDate() - 2);
  const isNew = createdDate > threeDaysAgo;

  return (
    <div className="col-md-6 col-sm-12">
      <Card className={clsx(classes.card, "card border-light-md shadow-xss")}>
      {isNew?<a className="fw-600 ms-auto font-xssss text-primary ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15">New</a>:<></>}
        <a
          onClick={(ev) => {
            ev.preventDefault();
            toMonopage();
          }}
          className={clsx(classes.moveToBtn, "pointer")}
        >
          <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i>
        </a>
        <CardActionArea onClick={() => {
          toMonopage();
        }}>
          {
            pageData.pageData.img ?
            <CardMedia
            className={classes.media}
            image={pageData.pageData.img.slice(0,4)==="http" ? pageData.pageData.img :process.env.REACT_APP_API_URL + pageData.pageData.img}
            title="Hotel"
          />:
          <CardMedia
            className={classes.media}
            image= {imageUrl}
            title="Hotel"
          />
          }
          
          <CardContent className={clsx(classes.cardContent, "mycard")}>
          {pageData.pageData.page_price?
            <h4 className= "fw-600 font-xsssss mt-auto position-absolute top---60 text-grey-600 ">¥{pageData.pageData.page_price}</h4>:
            <><h4 className= "fw-600 font-xsssss mt-auto position-absolute top---60 text-grey-600">¥0</h4></>}


          <figure className="mt-0 avatar me-0 pointer mb-1 fw-600 ms-auto position-absolute top---50 mb-5 ms-2 mt-0 right-15 d-flex">
            
          <Typography variant="body2" color="textSecondary" component="p" className = "mt-1 me-2 font-xssss text-grey-500">
            {t('Mono30')}
            </Typography>
            <div>

              {pageData.pageData.files ? (
    pageData.pageData.files.map((each, index) => (
      each ? (

          <img
            src={process.env.REACT_APP_API_URL + each.profile.img}
            alt="avatar"
            className="shadow-md me-2 rounded-circle w25"
          />

      ) : (

          <img
            src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
            alt="default avatar"
            className="shadow-md me-1 rounded-circle w25"
          />

      )
    ))
  ) : (

      <img
        src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
        alt="default avatar"
        className="shadow-md rounded-circle w25"
      />

  )}

</div>
  </figure>

            <Typography gutterBottom variant="h5" component="h2" className = "mt-3 black-typography">
              {pageData.pageData.title.slice(0,30)}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className = "black-typography">
              {pageData.pageData.created_on}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className = "text-grey-500 pe-5">
              {pageData.pageData.summary.slice(0,30)}..
            </Typography>
          </CardContent>
        </CardActionArea>
        {pageData.pageData.rating?
         <CardActions className="mycard d-block mb-2" >
         <div className="row">
           <div className="col-12">
             <h2 className="font-xssss ms-2  lh-1 mt-2 text-grey-900 fw-300 black-typography">
               {/* {Math.round(sum / rates.length)} */}
               {t('RecommendEach1')}{pageData.pageData.rating}
             </h2>
           </div>
         </div>
         <LinearProgress
           className={classes.linearProgress}
           color="info"
           determinate
           size="sm"
           value={pageData.pageData.rating}
           variant="plain"
         />
       </CardActions>
       :
        <CardActions className="mycard">
        <div className="row">
          <div className="col-12">
            <h2 className="font-xss  lh-1 mt-2 text-grey-900 fw-300 black-typography">
              {/* {Math.round(sum / rates.length)} */}
              0
            </h2>
          </div>
        </div>
        <LinearProgress
          className={classes.linearProgress}
          color="info"
          determinate
          size="sm"
          value="0"
          variant="plain"
        />
      </CardActions>
        }
       
      </Card>
    </div>
  );
};

export default RecommendEach;
